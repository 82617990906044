@import 'styles/includes.folkhem.scss';

.Breadcrumbs {
    $root: &;
    @include u-wrap-folkhem(site);
    @media print {
        display: none;
    }

    overflow-x: auto;

    &__Wrapper {
        border-top: 1px solid $colorGray30;
        border-bottom: 1px solid $colorGray30;
        display: flex;
        padding-top: 12px;
        padding-bottom: 14px;

        #{$root}--Footer & {
            padding-left: 0;
        }
    }

    &--Footer {
        padding-left: 0;
    }

    &__Item {
        position: relative;
        display: inline-block;
        margin: 0 6px 0 18px;
        white-space: nowrap;

        &:first-of-type {
            margin-left: 0;
        }

        &:after {
            content: '/';
            color: rgba($colorBlack, 0.6);
            position: absolute;
            top: 2px;
            right: -20px;
            display: block;
            width: 10px;
            height: auto;
            font-size: 1.4rem;
            text-decoration: none;
        }

        &--Last {
            padding-right: 20px;
            text-decoration: none;

            @include media(md) {
                padding-right: 0;
            }

            &:after {
                display: none;
            }
        }

        .Breadcrumbs--Footer & {
            @include media(md) {
                &:first-of-type {
                    margin: 0 10px;
                }
            }
            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    &__Link {
        color: rgba($colorBlack, 0.6);
        letter-spacing: 0.4px;
        font-size: 1.4rem;
        text-decoration: none;

        &:hover {
            color: rgba($colorBlack, 0.8);
        }

        &--Last:hover {
            color: rgba($colorBlack, 0.6);
        }
    }
}
