@import 'styles/includes.nordr.scss';

.SocialMedia {
    height: auto;
    margin: 32px 0;

    @include media(lg) {
        height: 100%;
        margin: 0;
    }

    &__Links {
        display: flex;
        height: auto;

        @include media(lg) {
            height: 100%;
        }
    }

    &__Link {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $gutter;
        margin-left: 0;
    }

    &__Icon {
        width: 25px;
        height: 25px;

        fill: $colorWhite;

        &--Facebook {
            width: 25px;
        }

        &--Twitter {
            width: 31px;
            height: 31px;
        }

        &--Linkedin {
            width: 24px;
            height: 24px;
        }
    }
}
