@import 'styles/includes.nordr.scss';

.Header {
    border-bottom: 1px solid $colorBrownLight40;

    &--Layout-our-homes {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    @include media(lg) {
        background-image: $backgdImgGradientLeft;

        &--Layout-our-homes {
            background-image: none;
        }
    }

    @media print {
        display: none;
    }

    .modal-open & {
        display: none;
    }

    &__Wrapper {
        @include u-wrap(site);

        @include media(md) {
            max-width: initial;
        }

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        height: 72px;

        @include media(lg) {
            justify-content: normal;
            flex-wrap: nowrap;
            align-items: center;
            height: 112px;
        }
    }

    &__NavScroll {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding-bottom: 64px;

        @include media(lg) {
            display: flex;
            flex-direction: row;
            overflow-y: visible;
            padding-bottom: 0;
        }
    }

    &--NavOpen {
        position: fixed;

        @include media(lg) {
            position: static;
        }
    }

    &__Container {
        display: flex;
        height: 100%;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 0 14px;
        z-index: 100;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            background-image: $backgdImgGradientLeft;
            width: calc(100% + 24px);
            top: 0;
            left: -12px;
            height: 72px;

            @include media(lg) {
                display: none;
            }
        }

        @include media(lg) {
            width: auto;
            padding: 0;
        }
    }

    &__Logo {
        position: relative;
        display: block;
    }

    &__LogoIcon {
        width: 88px;
        height: 40px;

        @include media(lg) {
            width: 158px;
            height: 32px;
        }
    }

    &__NavContainer {
        height: 0;
        width: 100%;
        z-index: 99;

        @include media(lg) {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__NavContent {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 72px);
        transform: translateY(-100%);
        transition: transform 0.2s;
        padding: 0 20px;

        @include media(lg) {
            transition: none;
            flex-direction: row;
            height: 100%;
            justify-content: flex-end;
            transform: translateY(0);
        }

        &--Active {
            transform: translateY(0);
            border-top: 1px solid $colorBrownLight40;
        }

        &::before,
        &::after {
            display: block;
            content: '';
            position: absolute;
            height: 100%;
            top: 0;
            background-color: $colorBrownLight20;
            z-index: -1;
            left: -12px;
            right: -12px;

            @include media(md) {
                left: -25%;
                right: -25%;
            }

            @include media(lg) {
                display: none;
            }
        }
    }

    &__Nav {
        padding: 32px 16px 0;

        @include media(lg) {
            display: flex;
            padding: 0;
        }

        @include media(lg) {
            flex-grow: 1;
            justify-content: flex-end;
            margin-right: 44px;
        }
    }

    &__NavLink {
        position: relative;
        display: flex;
        margin-bottom: 30px;
        color: black;
        font-weight: 500;
        font-size: 2rem;
        text-decoration: none;
        z-index: 20;
        align-items: center;
        letter-spacing: 1px;

        @include media(lg) {
            color: white;
        }

        @include media(lg) {
            font-size: 1.5rem;
            margin-bottom: 0;
            padding: 0 8px;
            height: 100%;

            .Header__Nav & {
                margin-left: 36px;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        &--Active {
            //color: rgba($colorBlack, 1);
        }

        &::after {
            @include media(lg) {
                background-color: white;
                bottom: 0;
                content: '';
                height: 4px;
                left: 0;
                opacity: 0;
                position: absolute;
                transition: opacity 0.2s;
                width: 100%;
            }
        }

        &:hover::after,
        &--Active::after {
            opacity: 1;
        }

        &--SubLink {
            font-size: 1.5rem;

            @include media(lg) {
                font-size: 1.3rem;
                margin-bottom: 8px;
                height: auto;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    text-decoration: underline;

                    &:after {
                        opacity: 0;
                    }
                }
            }
        }

        &--SubLinkActive {
            text-decoration: underline;
        }
    }

    &__SubNavWrapper {
        display: flex;
        flex-direction: column-reverse;

        @include media(lg) {
            flex-direction: row;
        }
    }

    &__SubNav {
        padding: 32px 16px 0;
        border-top: 1px solid $colorBrownLight40;

        @include media(lg) {
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-top: 0;
            flex-grow: 0;
            z-index: 0;
            margin-left: 16px;
        }
    }

    &__Login {
        flex-grow: 0;
        display: flex;
        align-items: center;
        border-top: 1px solid $colorBrownLight40;
        padding: 32px 16px 0;
        display: none;
        z-index: 21;

        @include media(lg) {
            border-top: none;
            padding: 0 32px 0 0;
        }
    }

    &__LoginLink {
        display: block;
    }

    &__LoggedIn {
        position: relative;
        background-image: url('#{$basepath}svg/icon-arrow-down-white.svg');
        background-repeat: no-repeat;
        background-position: right center;

        font-weight: 500;
        color: white;
        letter-spacing: 1.5px;
        width: 100%;
        cursor: default;

        @include media(lg) {
            width: auto;
            padding: 16px 24px 16px 8px;

            &:hover {
                .Header__LoggedInModal {
                    visibility: visible;
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }
    }

    &__LoggedInModal {
        opacity: 1;
        padding-top: 16px;

        @include media(lg) {
            padding-top: 0;
            visibility: hidden;
            transform: translateY(6px);
            transition: transform 0.25s, visibility 0.25s, opacity 0.25s ease;
            position: absolute;
            bottom: 0;
            right: 0;
            opacity: 0;
        }
    }

    &__LoggedInNav {
        background-color: $colorBrownLight20;

        @include media(lg) {
            border-radius: 2px;
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;

            box-shadow: 0 8px 10px 1px rgba($colorBlack, 0.14),
                0 3px 14px 2px rgba($colorBlack, 0.12),
                0 5px 5px -3px rgba($colorBlack, 0.2);
        }
    }

    &__LoggedInNavItem {
        width: 100%;
        display: inline-block;
        padding: 12px 16px;
        color: rgba($colorBlack, 0.8);
        font-size: 1.6rem;
        letter-spacing: 0.14px;
        line-height: calc(24 / 16);
        font-weight: 500;

        text-decoration: none;

        @include media(lg) {
            transition: background-color 0.25s ease;
            cursor: pointer;
            width: 260px;

            &:hover {
                background-color: $colorBrownLight40;
            }
        }
    }

    &__Hamburger {
        position: relative;
        width: 26px;
        height: 20px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;

        @include media(lg) {
            display: none;
        }

        span {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 9px;
            background-color: white;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 8px;
            }

            &:nth-child(4) {
                top: 16px;
            }

            .Header--NavOpen & {
                &:nth-child(1) {
                    top: 18px;
                    left: 50%;
                    width: 0;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    left: 50%;
                    width: 0;
                }
            }
        }
    }
}
