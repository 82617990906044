@import 'styles/includes.nordr.scss';

.LinkList {
    width: 100%;
    border-bottom: 1px solid rgba($colorWhite, 0.4);

    @include media(lg) {
        border-bottom: 0;
    }

    &--Last {
        @include media(lg) {
            border-top: 1px solid rgba($colorWhite, 0.4);;
            padding-top: 20px;
        }
    }

    &__Headline {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $colorWhite;
        display: block;
        width: 100%;
        padding: 17px 0 19px;
        font-size: 1.4rem;
        cursor: pointer;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(lg) {
            display: none;
            padding-top: 0;
        }
    }

    &__Button {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        background-image: url('#{$basepath}svg/icon-arrow-thin-white.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 16px;
        cursor: pointer;
        transition: transform 0.25s ease;
        transform: rotate(90deg);

        @include media(lg) {
            display: none;
        }

        .LinkList--Open & {
            transform: rotate(90deg) rotateY(160deg);
        }
    }

    &__Link {
        color: $colorWhite;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.5;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }

        &--Black {
            color: $colorBlack;
            opacity: 1;
        }
    }

    &__List {
        display: none;
        padding-bottom: 20px;

        @include media(lg) {
            display: block !important;
        }

        .LinkList--Open & {
            display: block;
        }
    }

    &__ListItem {
        margin-bottom: 10px;

        &:first-of-type {
            .LinkList__Link {
                @include media(lg) {
                    text-transform: uppercase;
                    color: $colorWhite;
                    font-weight: 500;
                    letter-spacing: 1px;
                }
            }
        }
    }
}
