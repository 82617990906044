@import 'styles/includes.nordr.scss';

.Footer {
    $root: &;

    @media print {
        display: none;
    }

    &__Above {
        #{$root}--WithinPageTypeHome & {
            display: none;
        }
    }

    &__MainLinkList {
        display: flex;
        flex-direction: column;

        @include media(md) {
            position: relative;
            border-top: 1px solid $colorBrownLight100;
            flex-direction: row;
            justify-content: center;
            margin-left: -$gutter;
        }
    }

    &__MainLinkListItem {
        position: relative;

        @include media(md) {
            position: unset;
            width: 50%;
            max-width: 504px;
            padding-left: 36px;
            margin-bottom: 0;

            &:last-of-type {
                border-left: 1px solid $colorBrownLight100;

                a {
                    padding-left: 46px;

                    &:before {
                        left: calc(50% + 1px); // 1px border
                    }
                }
            }
        }

        @include media(lg) {
            padding-left: 32px;
            max-width: 638px;

            &:last-of-type {
                a {
                    padding-left: 90px;
                }
            }
        }
    }

    &__MainLinkListItemLinkText {
        position: relative;
    }

    &__MainLinkListItemLink {
        align-items: center;
        background-color: $colorBrownLight20;
        border-top: 1px solid $colorBrownLight100;
        color: $colorGray100;
        font-weight: 500;
        letter-spacing: 0.8px;
        display: flex;
        font-size: 2.2rem;
        height: 104px;
        justify-content: flex-start;
        line-height: (32 / 24);
        text-decoration: none;
        padding-left: 12px;

        @include media(md) {
            border-top: none;
            padding-left: 0;
        }

        &:after {
            content: '';
            background-image: url('#{$basepath}svg/icon-arrow-right-nordr.svg');
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: left bottom;
            padding-top: 2px;
            display: inline-block;
            margin: 0 0 0px 16px;
            z-index: 1;
            width: 28px;
            height: 28px;
            transition: margin 0.15s ease;

            @include media(md) {
                padding-top: 4.5px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            width: 50%;
            height: calc(100%);
            background-color: $colorBlueLight40;
            top: 0;
            left: 0;
            z-index: 0;
            opacity: 0;
            transition: opacity 0.25s ease;
        }

        &:hover {
            &:before {
                opacity: 1;

                @media (hover: none) {
                    opacity: 0;
                }
            }
            &:after {
                margin: 0 0 0px 24px;

                @media (hover: none) {
                    margin: 0 0 0px 16px;
                }
            }
        }

        @include media(md) {
            font-size: 2.4rem;
            height: 224px;
        }
    }

    &__Dark {
        padding-top: 25px;
        padding-bottom: 58px;
        background-image: linear-gradient(90deg, $colorBlueDark100, $colorGradientGreen100);

        @include media(lg) {
            padding-top: 60px;
            padding-bottom: 30px;
        }
    }

    &__Wrap {
        @include u-wrap(site);

        @include media(md) {

        }
    }

    &__Container {
        display: flex;
        flex-direction: column;

        @include media(lg) {
            flex-direction: row;
            padding-bottom: 50px;
            border-bottom: 1px solid rgba($colorWhite, 0.4);
        }
    }

    &__Contact {
        color: $colorWhite;
        max-width: 100%;

        @include media(lg) {
            padding-left: $gutter;
            border-left: 1px solid rgba($colorWhite, 0.4);
            width: ($colWidthLg + $gutter) * 2;
        }

        @include media(xl) {
            width: ($colWidthXl + $gutter) * 2;
        }
    }

    &__ContactTitle {
        margin-top: 15px;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.45;
        letter-spacing: 1px;
        max-width: 280px;

        @include media(md) {
            margin-top: 0;
            margin-bottom: 15px;
            max-width: 100%;
        }
    }

    &__ContactAddress {
        * {
            margin-bottom: 15px;
            font-size: 1.4rem;
            letter-spacing: 0.03px;
            font-weight: 300;
            line-height: 1.5;
        }

        br {
            display: block;
            margin: 0;
        }

        a {
            color: $colorWhite;
            text-decoration: underline;
        }
    }

    &__ContactInfo {
        margin-bottom: 20px;
        font-size: 1.4rem;
        font-weight: 300;
        line-height: 1.5;
        max-width: 280px;

        @include media(md) {
            max-width: 100%;
        }

        a {
            color: $colorWhite;
            text-decoration: underline;
        }
    }

    &__CompanyInfo {
        display: flex;
        flex-direction: column;

        @include media(lg) {
            flex-direction: row;
            padding-top: 30px;
        }
    }

    &__DropdownContainer {
        width: 100%;

        @include media(lg) {
            width: auto;
            display: flex;
            margin-right: $colWidthLg + $gutter;
        }

        @include media(xl) {
            margin-right: $colWidthXl + $gutter;
        }
    }

    &__DropdownItem {
        @include media(lg) {
            width: $colWidthMd * 2;
            padding-left: 40px;
        }

        @include media(lg) {
            width: ($colWidthLg + $gutter) * 2;
        }

        @include media(xl) {
            width: ($colWidthXl + $gutter) * 2;
        }

        &:first-of-type .LinkList {
            border-top: 1px solid rgba($colorWhite, 0.4);;

            @include media(lg) {
                border-top: 0;
            }
        }
    }

    &__Footnote {
        margin-bottom: 24px;
        color: $colorWhite;
        font-size: 1.2rem;
        font-weight: 300;
        line-height: 1.33;
        letter-spacing: 0.4px;

        @include media(lg) {
            max-width: 660px;
            margin: 0 auto;
            padding-left: 24px;
            padding-right: 24px;
            width: 100%;
        }
    }

    &__SocialMedia {
        @include media(lg) {
            width: 40%;
        }

        @include media(xl) {
            width: 20%;
        }
    }

    &__Logo {
        display: block;
        width: 140px;
        height: 30px;
        background-image: url('#{$basepath}svg/logo-nordr-light.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @include media(lg) {
            float: right;
            width: 180px;
            min-width: 180px;
            height: 37px;
            min-height: 37px;
        }
    }

    &__LogoContainer {
        @include media(lg) {
            width: 20%;
        }
    }

    &__Breadcrumbs {
        border-bottom: 1px solid rgba($colorWhite, 0.4);
    }
}
