@import 'styles/includes.folkhem.scss';

.Footer {
    $root: &;

    @media print {
        display: none;
    }

    &__Above {
        @include u-wrap-folkhem(article);
        padding-top: 40px;
        padding-bottom: 20px;

        @include media(md) {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        #{$root}--WithinPageTypeHome & {
            display: none;
        }
    }

    &__MainLinkList {
        margin-left: -$gutter;
        display: flex;
        flex-direction: column;

        @include media(md) {
            flex-direction: row;
        }
    }

    &__MainLinkListItem {
        border-radius: 1px;
        margin-left: $gutter;
        margin-bottom: $gutter;

        @include media(md) {
            width: calc(50%);
            margin-bottom: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__MainLinkListItemLink {
        border-radius: 4px;
        color: rgba($colorBlack, 0.7);
        display: block;
        text-align: center;
        width: 100%;
        font-size: 1.6rem;
        font-weight: $fontWeightRegular;
        line-height: 1;
        position: relative;
        letter-spacing: 1.3px;
        padding: 30px 16px;
        text-decoration: none;
        background-color: $colorBrandLight;
        transition: background-color 0.15s ease;

        &:active {
            background-color: darken($colorBrandLight, 2%);
        }
    }

    &__Dark {
        padding-top: 25px;
        padding-bottom: 58px;
        background: $colorBrandDark;
        color: rgba(255, 255, 255, 0.6);
        font-weight: $fontWeightLight;
        font-size: 1.4rem;
        line-height: calc(20 / 14);

        @include media(lg) {
            padding-top: 60px;
            padding-bottom: 30px;
        }
    }

    &__Wrap {
        @include u-wrap-folkhem(site);
    }

    &__Container {
        display: flex;
        flex-direction: column;

        @include media(lg) {
            flex-direction: row;
            padding-bottom: 50px;
            border-bottom: 1px solid rgba($colorBlack, 0.16);
        }
    }

    &__Contact {
        max-width: 100%;

        @include media(lg) {
            border-right: 1px solid rgba($colorBlack, 0.16);
            padding-right: $colWidthSm;
            width: ($colWidthLg + $gutter) * 2 + $colWidthSm;
        }
    }

    &__ContactTitle {
        margin-bottom: 15px;
        font-size: 1.6rem;
        font-weight: $fontWeightRegular;
        line-height: calc(24 / 16);
        letter-spacing: 1px;
        max-width: 280px;
        color: rgba($colorWhite, 0.8);

        @include media(md) {
            max-width: 100%;
        }
    }

    &__ContactAddress {
        * {
            margin-bottom: 15px;
            font-size: 1.4rem;
            font-weight: 300;
            line-height: calc(20 / 14);
            color: rgba($colorWhite, 0.6);
        }

        br {
            display: block;
            margin: 0;
        }

        a {
            color: rgba($colorWhite, 0.6);
            font-weight: $fontWeightRegular;
            font-size: 1.4rem;
            line-height: calc(20 / 14);
            text-decoration: none;
            transition: color 0.1s;

            &:hover {
                color: rgba($colorWhite, 0.8);
            }
        }
    }

    &__ContactInfo {
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 280px;
        font-weight: $fontWeightLight;
        font-size: 1.4rem;
        line-height: calc(20 / 14);
        text-decoration: none;

        @include media(md) {
            max-width: 100%;
        }
    }

    &__CompanyInfo {
        display: flex;
        flex-direction: column;

        @include media(lg) {
            flex-direction: row;
            padding-top: 30px;
        }
    }

    &__DropdownContainer {
        width: 100%;

        @include media(lg) {
            width: auto;
            display: flex;
            margin-left: $colWidthSm;
        }
    }

    &__DropdownItem {
        @include media(lg) {
            width: (($colWidthLg + $gutter) * 2) + $gutter;
        }

        .LinkList {
            border-bottom: 1px solid rgba($colorBlack, 0.16);
            @include media(lg) {
                border-bottom: 0;

                & + .LinkList {
                    border-bottom: 1px solid rgba($colorBlack, 0.16);
                    padding-top: 20px;
                }
            }
        }
    }

    &__Footnote {
        margin-bottom: 24px;
        color: rgba($colorWhite, 0.6);
        font-size: 1.2rem;
        font-weight: $fontWeightRegular;
        line-height: calc(16 / 12);

        @include media(lg) {
            max-width: 660px;
            margin: 0 auto;
            padding-left: 24px;
            width: 100%;
        }
    }

    &__SocialMedia {
        @include media(lg) {
            width: 40%;
        }

        @include media(xl) {
            width: 20%;
        }
    }

    &__Logo {
        display: block;
        width: 56px;
        height: 56px;
        background-image: url('#{$basepath}svg/logo-folkhem-white.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @include media(lg) {
            float: right;
            width: 124px;
            height: 24px;
            min-height: 24px;
        }
    }

    &__LogoContainer {
        @include media(lg) {
            width: 20%;
        }
    }

    &__Breadcrumbs {
        display: none;
    }
}
