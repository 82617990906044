@import 'styles/includes.folkhem.scss';

.Btn {
    $btn-height: 48px;
    display: inline-block;
    height: $btn-height;
    padding: 0 30px;
    color: $colorWhite;
    font-size: 1.4rem;
    line-height: $btn-height;
    background-color: $colorSecondary;
    cursor: pointer;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 1px;
    background-image: linear-gradient(
        to bottom,
        transparent 50%,
        rgba(0, 0, 0, 0.08) 50.001%
    );
    background-size: auto 200%;
    background-repeat: no-repeat;
    background-position: center top;
    transition: background-position 0.3s cubic-bezier(0.54, 0.49, 0, 1.05);

    // &:hover {
    //     background-position: center bottom;
    // }

    @include media(md) {
        width: auto;
    }

    &--external {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 10px;
            background-image: url('#{$basepath}svg/icon-external.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &--reverse {
        flex-direction: row-reverse;
        &:after {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &--close {
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 2.6rem;
        font-weight: 300;
        line-height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);

        @include media(md) {
            width: 50px;
            height: 50px;
            font-size: 3rem;
            line-height: 50px;
        }

        &::after {
            content: '\0000D7';
            position: relative;
            top: 0;
        }
    }

    &--up {
        background-color: transparent;
        background-image: url('#{$basepath}svg/material-design/navigation/ic_expand_less_24px.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 24px;
        border: 1px solid $colorBrand;
        border-radius: 50%;
        display: block;
        height: 40px;
        padding: 0;
        width: 40px;

        &:hover {
            background-position: center;
        }
    }

    &--rounded {
        border-radius: 30px;
    }

    &--brand {
        background-color: $colorBrand;
        color: white;
    }

    &--red {
        background-color: $colorBrand;
    }

    &--black {
        background-color: $colorGray90;
    }

    &--gray {
        background-color: $colorGray40;
        color: #000000;
    }

    &--folkhem-green {
        background: transparent;
        border: 1px solid $colorBrand;
        border-radius: 2px;
        color: $colorBrand;
    }

    &--search {
        align-items: center;
        background-color: $colorBrandDark;
        display: flex;
        font-weight: 400;
        height: 46px;
        justify-content: center;
        padding: 0 45px;
        transition: color 0.5s,
            background-position 0.3s cubic-bezier(0.54, 0.49, 0, 1.05);
        letter-spacing: 0.2px;
        font-family: $fontFamilyBase;

        @include media(md) {
            height: 66px;
        }

        &::after {
            background-image: url('#{$basepath}svg/icon-search.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            height: 16px;
            margin-left: 10px;
            width: 16px;
            transition: opacity 0.5s;
        }

        &:disabled {
            color: rgba(255, 255, 255, 0.3);

            &:after {
                opacity: 0.3;
            }
        }
    }
}
