@import 'styles/includes.nordr.scss';

.Breadcrumbs {
    $root: &;

    overflow-x: auto;
    @media print {
        display: none;
    }

    &__Wrapper {
        @include u-wrap(site);
        display: flex;
        padding-top: 12px;
        padding-bottom: 14px;

        #{$root}--Footer & {
            padding-left: 0;
        }
    }

    &--Footer {
        padding-left: 0;
    }

    &__Item {
        position: relative;
        display: inline-block;
        margin: 0 10px;
        white-space: nowrap;

        &:first-of-type {
            margin-left: 0;
        }

        &:after {
            content: '/';
            position: absolute;
            top: 3px;
            right: -16px;
            display: block;
            width: 10px;
            height: auto;
            color: $colorBlack;
            font-size: 1.2rem;
            text-decoration: none;
            opacity: 0.3;

            .Breadcrumbs--Layout-our-homes & {
                color: white;
            }
        }

        #{$root}--Footer & {
            &:after {
                color: $colorWhite;
            }
        }

        &--Last {
            padding-right: 20px;
            text-decoration: none;

            @include media(md) {
                padding-right: 0;
            }

            &:after {
                display: none;
            }
        }

        .Breadcrumbs--Footer & {
            @include media(md) {
                &:first-of-type {
                    margin: 0 10px;
                }
            }
            &:first-of-type {
                margin-left: 0;
            }
        }
    }

    &__Link {
        font-size: 1.2rem;
        letter-spacing: 0.4px;
        text-decoration: none;
        color: $colorGray100;

        #{$root}--Footer &,
        #{$root}--Layout-our-homes & {
            color: $colorWhite;
        }

        &:hover {
            opacity: 1;
        }
    }
}
