@import 'styles/includes.folkhem.scss';

.LinkList {
    width: 100%;
    border-bottom: 1px solid rgba($colorBlack, 0.16);

    @include media(lg) {
        border-bottom: 0;
    }

    &--Last {
        @include media(lg) {
            border-bottom: 1px solid rgba($colorBlack, 0.16);
            padding-top: 20px;
        }
    }

    &__Headline {
        display: block;
        width: 100%;
        padding: 17px 0 19px;
        font-size: 1.4rem;
        cursor: pointer;
        height: 56px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(lg) {
            display: none;
            padding-top: 0;
        }
    }

    &__Button {
        display: block;
        float: right;
        width: 20px;
        height: 20px;
        mask: url('#{$basepath}svg/icon-arrow-thin.svg');
        background-color: rgba(255, 255, 255, 0.6);
        mask-size: contain;
        cursor: pointer;
        transform: rotate(90deg);

        @include media(lg) {
            display: none;
        }

        .LinkList--Open & {
            transform: rotate(-90deg);
        }
    }

    &__Link {
        color: rgba($colorWhite, 0.6);
        font-weight: $fontWeightRegular;
        font-size: 1.4rem;
        line-height: calc(20 / 14);
        text-decoration: none;
        transition: color 0.1s;

        &:hover {
            color: rgba($colorWhite, 0.8);
        }
    }

    &__List {
        display: none;
        padding-bottom: 20px;

        @include media(lg) {
            display: block !important;
        }

        .LinkList--Open & {
            display: block;
        }
    }

    &__ListItem {
        margin-bottom: 10px;

        &:first-of-type {
            .LinkList__Link {
                @include media(md) {
                    font-size: 1.6rem;
                    color: rgba($colorWhite, 0.8);
                    font-weight: $fontWeightRegular;

                    &:hover {
                        color: rgba($colorWhite, 0.9);
                    }
                }
            }
        }
    }
}
