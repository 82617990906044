@import 'styles/includes.nordr.scss';

.Btn {
    $root: &;
    font-family: $fontFamilyBase;

    position: relative;
    overflow: hidden;
    z-index: 0;
    letter-spacing: 1px;

    $btn-height: 48px;
    display: inline-block;
    height: $btn-height;
    padding: 0 32px;
    color: $colorWhite;
    font-size: 1.5rem;
    line-height: $btn-height - 4;
    background-color: $colorBlueDark100;
    cursor: pointer;
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: none;
    border-radius: 2px;
    background-image: none;
    transition: background-image 0.25s ease;

    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: -100%;
        z-index: -1;
        width: calc(100%);
        height: 100%;
        transition: right 0.5s ease;
        background-image: linear-gradient(270deg, rgba($colorBlueDark100, 1) 0%, rgba($colorGradientGreen100, 1) 50%, rgba($colorBlueDark100, 1) 100%);
    }

    &:hover {
        &:before {
            right: 50%;
        }
    }

    @include media(md) {
        width: auto;
    }

    &--external {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-left: 10px;
            background-image: url('#{$basepath}svg/icon-external.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }

    &--reverse {
        flex-direction: row-reverse;
        &:after {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &--outline {
        &:before {
            display: none;
        }
        border: 1px solid #fff;
        background-color: transparent;
        border-radius: 2px;
    }

    &--outline-grey {
        &:before {
            display: none;
        }
        border: 1px solid #333333;
        background-color: transparent;
        border-radius: 2px;
    }

    &--close {
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        padding: 0;
        font-size: 2.6rem;
        font-weight: 300;
        line-height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);

        @include media(md) {
            width: 50px;
            height: 50px;
            font-size: 3rem;
            line-height: 50px;
        }

        &:before {
            content: none;
        }

        &::after {
            content: '\0000D7';
            position: relative;
            top: 0px;
            opacity: 1;
            right: initial;
            left: 0;
        }
    }

    &--up {
        background-color: transparent;
        background-image: url('#{$basepath}svg/icon-arrow-up.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px;
        border: 1px solid $colorGray100;
        border-radius: 50%;
        display: block;
        height: 40px;
        padding: 0;
        width: 40px;
        transition: none;

        &:before {
            content: none;
        }

        &:hover {
            background-image: url('#{$basepath}svg/icon-arrow-up-white.svg');
            background-color: $colorGray100;
            background-position: center;
        }
    }

    &--rounded {
        border-radius: 30px;
    }

    &--white {
        background-color: white;
        color: black;
    }

    &--red {
        background-color: $colorBlueDark100;
    }

    &--black {
        background-color: $colorGray100;
    }

    &--blue-dark {
        background-color: $colorBlueDark100;
    }

    &--gray {
        background-color: $colorGray100;
        color: $colorWhite;
        transition: background-color 0.25s ease;

        &:hover {
            background-color: rgba($colorGray100, 0.8);
        }

        &:before {
            content: none;
        }
    }

    &--search {
        align-items: center;
        display: flex;
        font-weight: 400;
        height: 46px;
        justify-content: center;
        padding: 0 45px;
        transition: color 0.5s,
        background-position 0.3s cubic-bezier(0.54, 0.49, 0, 1.05);
        letter-spacing: 0.2px;
        font-family: $fontFamilyBase;

        @include media(md) {
            height: 66px;
        }

        &::before {
            background-image: url('#{$basepath}svg/icon-search.svg');
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
            display: inline-block;
            height: 16px;
            margin-right: 10px;
            width: 16px;
            transition: opacity 0.5s;
        }

        &:disabled {
            color: rgba(255, 255, 255, 0.3);

            &:after {
                opacity: 0.3;
            }
        }
    }
}
