@import 'styles/includes.folkhem.scss';

.Header {
    $root: &;
    @include u-wrap-folkhem(site);

    @media print {
        display: none;
    }

    .modal-open & {
        display: none;
    }

    &__Wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media(lg) {
            justify-content: normal;
            flex-wrap: nowrap;
            align-items: center;
            height: 120px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &--NavOpen {
        position: fixed;

        @include media(lg) {
            position: static;
        }
    }

    &__Container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px 0 14px;
        z-index: 100;
        background: $colorSecondaryLight;

        @include media(lg) {
            width: auto;
            padding: 0;
        }
    }

    &__Logo {
        display: block;
        margin-top: 4px;

        @include media(lg) {
            margin-top: 0;
        }
    }

    &__LogoIcon {
        width: 128px;
        height: 24px;
        fill: $colorBrandDark;

        @include media(lg) {
            width: 178px;
            height: auto;
        }
    }

    &__NavContainer {
        height: 0;
        position: relative;
        width: 100%;
        z-index: 99;

        @include media(lg) {
            display: block;
            height: auto;
            margin-left: auto;
        }

        @include media(xl) {
            width: 888px;
        }
    }

    &__NavScroll {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        padding-bottom: 64px;
        opacity: 0;
        transition: opacity 0.25s 0.15s ease;

        #{$root}__NavContent--Active & {
            opacity: 1;
        }

        @include media(md) {
            transition: none;
        }

        @include media(lg) {
            opacity: 1;
            display: flex;
            justify-content: flex-end;
            flex-direction: row;
            overflow-y: visible;
            padding-bottom: 0;
        }
    }

    &__NavContent {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 60px);
        transform: translateY(-100%);
        opacity: 0;
        transition: transform 0.25s ease, opacity 0.25s ease;

        @include media(md) {
            transition: none;
            padding: 20px 0;
        }

        @include media(lg) {
            opacity: 1;
            flex-direction: row;
            height: auto;
            transform: translateY(0);
        }

        &--Active {
            opacity: 1;
            transform: translateY(0);
            background-color: $colorSecondaryLight;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: inherit;
            z-index: -1;
            display: none;

            @include media(lg) {
                display: none;
            }
        }

        &::before {
            left: -100%;
        }

        &::after {
            right: -100%;
        }

        &--Active::before,
        &--Active::after {
            display: block;

            @include media(lg) {
                display: none;
            }
        }
    }

    &__Nav {
        padding: 15px 15px 0;

        @include media(lg) {
            display: flex;
            padding: 0;
        }
    }

    &__NavLink {
        position: relative;
        display: flex;
        margin-bottom: 32px;
        font-weight: $fontWeightLight;
        color: rgba($colorBrandDark, 0.8);
        font-size: 2rem;
        line-height: (24/20);
        text-decoration: none;
        //z-index: 20;
        transition: color 0.1s;
        align-items: center;
        letter-spacing: 0.4px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media(lg) {
            font-size: 2rem;
            margin-bottom: 0;
            margin-right: $gutter;
            text-align: center;
            padding: 12px;
            border-radius: 4px;
            transition: background 0.15s ease;

            &:hover {
                background: #e6efeb;
            }

            &:active {
                background: darken(#e6efeb, 4%);
            }
        }

        &--Active {
        }

        &:hover::after,
        &--Active::after {
            opacity: 1;
        }

        &--SubLink {
            @include media(lg) {
                font-size: 1.3rem;
                margin-bottom: 8px;
                height: auto;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    &:after {
                        opacity: 0;
                    }
                }
            }
        }
    }

    &__SubNavWrapper {
        display: flex;
        flex-direction: column-reverse;

        @include media(lg) {
            flex-direction: row;
        }
    }

    &__SubNav {
        display: block;
        padding: 30px 15px 0;

        @include media(lg) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0;
            margin-left: 56px;
            border-top: 0;

            & .Header__NavLink {
                font-size: 1.4rem;
                line-height: (20/14);
                color: $colorBlackFill30;

                padding: 0;
                margin-left: 16px;
                margin-right: 0;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    background: none;
                    color: $colorBlack;
                }
            }
        }
    }

    &__SubNavLink {
        color: rgba($colorBlack, 0.6);
        font-size: 1.6rem;
        line-height: 1.45;
        text-decoration: none;
        transition: color 0.1s;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.4rem;
            font-weight: $fontWeightRegular;
        }

        &:hover {
            color: rgba($colorBlack, 0.8);
        }
    }

    &__SubNavItem {
        margin-bottom: 20px;
        margin-left: 16px;

        @include media(lg) {
            margin-bottom: 5px;
        }
    }

    &__LoggedIn {
        display: none;
    }

    &__Hamburger {
        position: relative;
        width: 26px;
        height: 20px;
        cursor: pointer;
        transform: rotate(0deg);
        transition: 0.5s ease-in-out;

        @include media(lg) {
            display: none;
        }

        span {
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 9px;
            background-color: $colorBlack;
            opacity: 1;
            transform: rotate(0deg);
            transition: 0.25s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: 8px;
            }

            &:nth-child(4) {
                top: 16px;
            }

            .Header--NavOpen & {
                &:nth-child(1) {
                    top: 18px;
                    left: 50%;
                    width: 0;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    left: 50%;
                    width: 0;
                }
            }
        }
    }
}
